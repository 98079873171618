<template>
  <div :class="avatarClass">
      <img :src="src" alt="">
  </div>
</template>

<script>
export default {
  name: "Avatar",
  props: {
  		size: {
  				type: String,
          default: "lg"
      },
      src: String,
  },
  computed: {
  		avatarClass() {
  				return {
  						"lk-avatar" : true,
  						"lg" : this.size === "lg",
              "sm" : this.size === "sm",
          };
      },
  },
}
</script>

<style scoped lang="scss">
  .header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .lk-avatar img {
    box-shadow: none;
  }
  .lk-avatar {
    color: #fff;
    background: #28c76f;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
    &.lg {
       width: 80px;
       height: 80px;
      font-size: 30px;
     }
    &.sm {
      width: 40px;
      height: 40px;
      font-size: 16px;
    }
  }
</style>
