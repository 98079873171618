var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-1","no-close-on-backdrop":"","content-class":"shadow","title":"Создать задачу","size":"lg"},on:{"show":_vm.showModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":function($event){return _vm.$bvModal.hide('modal-1')}}},[_vm._v(" Отмена ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","disabled":_vm.submitDisable},on:{"click":_vm.formSubmit}},[_vm._v(" Создать ")])]},proxy:true}])},[(_vm.isLoad)?_c('div',{staticClass:"slk-loader"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1):_vm._e(),_c('validation-observer',{ref:"createTaskForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Проект"}},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.currentProject),callback:function ($$v) {_vm.currentProject=$$v},expression:"currentProject"}})],1),_c('b-form-group',{attrs:{"label":"Тип задачи"}},[_c('b-form-select',{attrs:{"options":_vm.taskTypeList,"value-field":"id","text-field":"name","required":""},model:{value:(_vm.dataTaskType),callback:function ($$v) {_vm.dataTaskType=$$v},expression:"dataTaskType"}})],1)],1)],1),_c('hr'),_c('b-form-group',{attrs:{"label":"Название задачи"}},[_c('validation-provider',{attrs:{"name":"taskName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Название задачи"},model:{value:(_vm.taskName),callback:function ($$v) {_vm.taskName=$$v},expression:"taskName"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("Поле обязательно для заполнения")]):_vm._e()]}}],null,true)})],1)],1),_c('div',{staticClass:"mt-1 mb-1"},[_c('label',[_vm._v("Описание задачи")]),_c('quill-text-editor',{model:{value:(_vm.taskDesc),callback:function ($$v) {_vm.taskDesc=$$v},expression:"taskDesc"}})],1),_c('b-form',[_c('b-form-group',{attrs:{"label":"Исходная оценка"}},[_c('time-input',{model:{value:(_vm.taskEstimate),callback:function ($$v) {_vm.taskEstimate=$$v},expression:"taskEstimate"}})],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Направление разработки"}},[_c('b-form-select',{attrs:{"options":_vm.taskLabelList,"placeholder":"Направление разработки","value-field":"id","text-field":"name"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("-- Ничего не выбрано --")])]},proxy:true}],null,true),model:{value:(_vm.taskLabel),callback:function ($$v) {_vm.taskLabel=$$v},expression:"taskLabel"}})],1),(!_vm.isEpic)?_c('b-form-group',{attrs:{"label":"Epick Link"}},[_c('v-select',{attrs:{"options":_vm.taskEpicLinkList,"label":"name","placeholder":"Выберите эпик"},model:{value:(_vm.taskEpicLink),callback:function ($$v) {_vm.taskEpicLink=$$v},expression:"taskEpicLink"}})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Исполнитель"}},[_c('v-select',{attrs:{"options":_vm.taskUserList,"placeholder":"Выберите исполнителя","label":"firstname"},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var firstname = ref.firstname;
var lastname = ref.lastname;
var avatar = ref.avatar;
return [_c('div',{staticClass:"vSelect-studio-img"},[_c('div',{staticClass:"vSelect-avatar"},[_c('img',{attrs:{"src":avatar}})]),_c('span',[_vm._v(" "+_vm._s(firstname)+" "+_vm._s(lastname))])])]}},{key:"option",fn:function(ref){
var firstname = ref.firstname;
var lastname = ref.lastname;
var avatar = ref.avatar;
return [_c('div',{staticClass:"vSelect-studio-img"},[_c('div',{staticClass:"vSelect-avatar"},[_c('img',{attrs:{"src":avatar}})]),_c('span',[_vm._v(" "+_vm._s(firstname)+" "+_vm._s(lastname))])])]}}],null,true),model:{value:(_vm.taskUser),callback:function ($$v) {_vm.taskUser=$$v},expression:"taskUser"}})],1),_c('b-form-group',{attrs:{"label":"Приоритет"}},[_c('validation-provider',{attrs:{"name":"taskPriority","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.taskPriorityList,"placeholder":"Выберите приоритет","label":"name"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var icon = ref.icon;
return [_c('span',[_vm._v(_vm._s(icon)+" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.taskPriority),callback:function ($$v) {_vm.taskPriority=$$v},expression:"taskPriority"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("Поле обязательно для заполнения")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Sprint"}},[_c('b-form-select',{attrs:{"options":_vm.taskSprintList,"placeholder":"Выберите спритн","value-field":"uuid","text-field":"name"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("-- Ничего не выбрано --")])]},proxy:true}],null,true),model:{value:(_vm.taskSprint),callback:function ($$v) {_vm.taskSprint=$$v},expression:"taskSprint"}})],1),_c('b-form-group',{attrs:{"label":"Check in build (iOS)"}},[_c('b-form-input',{attrs:{"placeholder":"Check in build (iOS)"},on:{"keyup":_vm.validateIso},model:{value:(_vm.taskBuildIos),callback:function ($$v) {_vm.taskBuildIos=$$v},expression:"taskBuildIos"}})],1),_c('b-form-group',{attrs:{"label":"Check in build (Android)"}},[_c('b-form-input',{attrs:{"placeholder":"Check in build (Android)"},on:{"keyup":_vm.validateAndroid},model:{value:(_vm.taskBuildAndroid),callback:function ($$v) {_vm.taskBuildAndroid=$$v},expression:"taskBuildAndroid"}})],1),_c('b-form-group',{attrs:{"label":"Story Points"}},[_c('b-form-input',{attrs:{"placeholder":"Story Points","type":"number"},model:{value:(_vm.taskStoryPoint),callback:function ($$v) {_vm.taskStoryPoint=$$v},expression:"taskStoryPoint"}})],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }