<template>
  <b-modal
    id="modal-2"
    no-close-on-backdrop
    content-class="shadow"
    title="Создать проект"
    size="lg"
    @show="showModal"
  >
    <div class="slk-loader" v-if="isLoad">
      <b-spinner label="Loading..." />
    </div>
    <validation-observer ref="createTaskForm" #default="{ invalid }">
      <b-form>
        <!--
      <b-row>
        <b-col sm="6">
          <b-form-group label="Проект">
            <b-form-input v-model="currentProject" disabled/>
          </b-form-group>
          
          <b-form-group label="Тип задачи">
            <b-form-select
              v-model="dataTaskType"
              :options="taskTypeList"
              value-field="id"
              text-field="name"
              required
            />
          </b-form-group>
          
        </b-col>
      </b-row>
      <hr> -->

        <b-form-group label="Название проекта">
          <validation-provider #default="{ errors }" name="projectName" rules="required">
            <b-form-input v-model="projectName" placeholder="Название проекта" />
            <small class="text-danger" v-if="errors[0]">Поле обязательно для заполнения</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Ключ проекта">
          <validation-provider #default="{ errors }" name="keyProject" rules="required">
            <b-form-input v-model="keyProject" placeholder="Ключ проекта" />
            <small class="text-danger" v-if="errors[0]">Поле обязательно для заполнения</small>
          </validation-provider>
        </b-form-group>
        <!--
        <b-form-group label="Описание задачи">
          <quill-editor v-model="taskDesc" :options="editorOption"> </quill-editor>
        </b-form-group>
        
        <b-form-group label="Исходная оценка">
          <time-input v-model="taskEstimate"></time-input>
        </b-form-group>
        -->
        <b-row>
          <b-col sm="10">
            <b-form-group label="Участники">
              <v-select
                v-model="projectUserList"
                :options="allUserList"
                :filter="fuseSearch"
                placeholder="Выберите участников"
                label="firstname"
                multiple
              >
                <template #selected-option="{ id, firstname, lastname, avatar }">
                  <div class="vSelect-studio-img">
                    <div class="vSelect-avatar"><img :src="avatar" /></div>
                    <span> {{ firstname }} {{ lastname }}</span>
                  </div>
                </template>
                <template #option="{ id, firstname, lastname, avatar }">
                  <div class="vSelect-studio-img">
                    <div class="vSelect-avatar"><img :src="avatar" /></div>
                    <span> {{ firstname }} {{ lastname }}</span>
                  </div>
                </template>
              </v-select>
            </b-form-group>

            <b-form-group label="Workflow">
              <v-select
                v-model="workflowList"
                :options="workflowAllSteps"
                placeholder="Выберите Workflows"
                label="name"
                multiple
              >
                <template #option="{ name }">
                  <span>{{ name }}</span>
                </template>
              </v-select>
              <!--
              <small class="text-danger" v-if="errors[0]">Поле обязательно для заполнения</small>  -->
            </b-form-group>
          </b-col>
          <b-col sm="6"> </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <button type="button" class="btn btn-secondary" @click="$bvModal.hide('modal-2')">
        Отмена
      </button>
      <button type="button" class="btn btn-primary" @click="formSubmit" :disabled="submitDisable">
        Создать
      </button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BInputGroup,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BFormTags,
  BFormTag,
  BSpinner,
  BFormSelectOption,
} from 'bootstrap-vue';

import vSelect from 'vue-select';
// eslint-disable-next-line
import 'quill/dist/quill.core.css';
// eslint-disable-next-line
import 'quill/dist/quill.snow.css';
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css';

import { taskApi, projectApi } from '@/api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Fuse from 'fuse.js';
import { required } from '@validations';
import { getErrors } from '@/helpers/errors';
import { mapActions, mapState } from 'vuex';
import axios from 'axios';

export default {
  name: 'ModalTaskForm',
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    vSelect,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
  },
  /* props: {
		  taskType: {
          type: Number,
          default: 1
      },
  }, */
  data: () => ({
    projectName: '',
    keyProject: '',
    projectUserList: [],
    allUserList: [],
    workflowAllSteps: [],
    workflowList: [],

    isLoad: false,

    submitDisable: false,
    required,
  }),
  watch: {},
  computed: {
    ...mapState('project', ['isPending']),
    availableTaskLabelList() {
      return this.taskLabelList.filter((opt) => this.taskLabel.indexOf(opt) === -1);
    },
    currentProject() {
      let params = this.$route.params;
      if (params.hasOwnProperty('project')) return this.$route.params.project;

      const projects = JSON.parse(localStorage.getItem('projects'));
      return projects[0]['key'];
    },
  },
  methods: {
    ...mapActions('project', ['getBacklogTasks', 'getSprints', 'getBoard', 'getEpics']),
    ...mapActions('projects', ['getProjects']),
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['id', 'firstname', 'lastname'],
        findAllMatches: false,
        includeScore: true,
        shouldSort: true,
        threshold: 0.1,
      });
      return search.length
        ? fuse.search(search).map(({ item }) => {
            return item;
          })
        : fuse.list;
    },
    validateIso(e) {
      this.taskBuildIos = e.target.value.replace(/[^0-9\.]/g, '');
    },
    validateAndroid(e) {
      this.taskBuildAndroid = e.target.value.replace(/[^0-9\.]/g, '');
    },
    formSubmit() {
      this.$refs.createTaskForm.validate().then((success) => {
        if (success) {
          this.submitDisable = true;
          const project = {
            name: this.projectName,
            key: this.keyProject,
            user_ids: this.projectUserList.map(({ id }) => id),
            workflow_step_ids: this.workflowList.map(({ id }) => id),
          };

          projectApi
            .create(project)
            .then((res) => {
              this.$bvModal.hide('modal-2');

              // Отображаем сообщение о создании задачи
              this.makeSuccessToast(res.data.key);
              console.log('res.data:', res.data);

              // Очищаем данные формы
              this.clearForm();

              // Обновляем список задач в бэклоге
              this.getProjects();
              /*
              if (this.$route.name == 'projects-backlog') {
                this.$store.dispatch('project/updatePending', true);
                axios.all([this.getBacklogTasks(), this.getSprints(), this.getEpics()]).then(() => {
                  this.$store.dispatch('project/updatePending', false);
                });
              }
              // Обновляем список эпиков
              // Обновляем канбан
              if (this.$route.name == 'board') {
                this.getBoard();
              } */
              this.submitDisable = false;
            })
            .catch((error) => {
              if (error.response) {
                const errors = getErrors(error.response.data.errors);
                if (errors.general) {
                  this.makeErrorToast(errors.general[0].status, errors.general[0].message);
                }
              }
              this.submitDisable = false;
            });
        }
      });
    },
    showModal() {
      this.isLoad = true;
      const getAllUsers = projectApi.getAllUsers();
      const getWorkflowSteps = projectApi.getWorkflowSteps();

      Promise.all([getAllUsers, getWorkflowSteps])
        .then((results) => {
          this.allUserList = results[0].data.users;

          this.workflowAllSteps = results[1].data.workflow_steps;
        })
        .then(() => {
          this.isLoad = false;
        });
    },
    makeSuccessToast(key) {
      const h = this.$createElement;
      const href =  '/project/' + key + '/backlog';
      console.log('href:', href);
      const vNodesContent = h('div', {}, [
        h('span', {}, 'Проект с ключом '),
        h('a', { attrs: { href: href }, class: 'font-weight-bold' }, key),
        h('span', {}, ' успешно создан'),
      ]);

      this.$bvToast.toast([vNodesContent], {
        title: this.$t('Сreating a task'),
        variant: 'success',
        solid: true,
      });
    },
    makeErrorToast(status, message) {
      this.$bvToast.toast(`Ошибка при создании задачи. ERROR: ${status} ${message}`, {
        title: this.$t('Сreating a task'),
        variant: 'danger',
        solid: true,
      });
    },
    updateProjectUserList() {
      this.projectUserList = [...this.projectUserList, ''];
      // console.log('projectUserList:', this.projectUserList);
    },
    clearForm() {
      this.projectName = '';
      this.keyProject = '';
      this.projectUserList = [];
      this.workflowList = [];
    },
  },
};
</script>

<style scoped></style>
