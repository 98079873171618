<template>
  <b-modal
    id="modal-1"
    no-close-on-backdrop
    content-class="shadow"
    title="Создать задачу"
    size="lg"
    @show="showModal"
  >
    <div class="slk-loader" v-if="isLoad">
      <b-spinner label="Loading..." />
    </div>
    <validation-observer ref="createTaskForm" #default="{ invalid }">
      <b-form>
        <b-row>
          <b-col sm="6">
            <b-form-group label="Проект">
              <b-form-input v-model="currentProject" disabled />
            </b-form-group>
            <b-form-group label="Тип задачи">
              <b-form-select
                v-model="dataTaskType"
                :options="taskTypeList"
                value-field="id"
                text-field="name"
                required
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr />

        <b-form-group label="Название задачи">
          <validation-provider #default="{ errors }" name="taskName" rules="required">
            <b-form-input v-model="taskName" placeholder="Название задачи" />
            <small class="text-danger" v-if="errors[0]">Поле обязательно для заполнения</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <div class="mt-1 mb-1" ><label>Описание задачи</label>
      <quill-text-editor v-model="taskDesc"> </quill-text-editor>
      </div>
      <b-form>
        <b-form-group label="Исходная оценка">
          <time-input v-model="taskEstimate"></time-input>
        </b-form-group>

        <b-row>
          <b-col sm="6">
            <b-form-group label="Направление разработки">
              <b-form-select
                v-model="taskLabel"
                :options="taskLabelList"
                placeholder="Направление разработки"
                value-field="id"
                text-field="name"
              >
                <template #first>
                  <b-form-select-option :value="null">-- Ничего не выбрано --</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group v-if="!isEpic" label="Epick Link">
              <v-select
                v-model="taskEpicLink"
                :options="taskEpicLinkList"
                label="name"
                placeholder="Выберите эпик"
              ></v-select>
            </b-form-group>

            <b-form-group label="Исполнитель">
              <v-select
                v-model="taskUser"
                :options="taskUserList"
                placeholder="Выберите исполнителя"
                label="firstname"
              >
                <template #selected-option="{ firstname, lastname, avatar }">
                  <div class="vSelect-studio-img">
                    <div class="vSelect-avatar"><img :src="avatar" /></div>
                    <span> {{ firstname }} {{ lastname }}</span>
                  </div>
                </template>
                <template #option="{ firstname, lastname, avatar }">
                  <div class="vSelect-studio-img">
                    <div class="vSelect-avatar"><img :src="avatar" /></div>
                    <span> {{ firstname }} {{ lastname }}</span>
                  </div>
                </template>
              </v-select>
            </b-form-group>

            <b-form-group label="Приоритет">
              <validation-provider #default="{ errors }" name="taskPriority" rules="required">
                <v-select
                  v-model="taskPriority"
                  :options="taskPriorityList"
                  placeholder="Выберите приоритет"
                  label="name"
                >
                  <template #option="{ name, icon }">
                    <span>{{ icon }} {{ name }}</span>
                  </template>
                </v-select>
                <small class="text-danger" v-if="errors[0]">Поле обязательно для заполнения</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Sprint">
              <b-form-select
                v-model="taskSprint"
                :options="taskSprintList"
                placeholder="Выберите спритн"
                value-field="uuid"
                text-field="name"
              >
                <template #first>
                  <b-form-select-option :value="null">-- Ничего не выбрано --</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group label="Check in build (iOS)">
              <b-form-input
                placeholder="Check in build (iOS)"
                v-model="taskBuildIos"
                @keyup="validateIso"
              />
            </b-form-group>
            <b-form-group label="Check in build (Android)">
              <b-form-input
                placeholder="Check in build (Android)"
                v-model="taskBuildAndroid"
                @keyup="validateAndroid"
              />
            </b-form-group>

            <b-form-group label="Story Points">
              <b-form-input placeholder="Story Points" type="number" v-model="taskStoryPoint" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <button type="button" class="btn btn-secondary" @click="$bvModal.hide('modal-1')">
        Отмена
      </button>
      <button type="button" class="btn btn-primary" @click="formSubmit" :disabled="submitDisable">
        Создать
      </button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BInputGroup,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BFormTags,
  BFormTag,
  BSpinner,
  BFormSelectOption,
} from 'bootstrap-vue';
import TimeInput from '../components/TimeInput';
import vSelect from 'vue-select';
// eslint-disable-next-line
import 'quill/dist/quill.core.css';
// eslint-disable-next-line
import 'quill/dist/quill.snow.css';
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css';

import QuillTextEditor from '../components/QuillTextEditor';

import { taskApi, projectApi } from '@/api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import { getErrors } from '@/helpers/errors';
import { mapActions, mapState } from 'vuex';
import axios from 'axios';

export default {
  name: 'ModalTaskForm',
  components: {
    BModal,
    BForm,
    BInputGroup,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormTags,
    BFormTag,
    vSelect,
    QuillTextEditor,
    TimeInput,
    BSpinner,
    BFormSelectOption,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    taskType: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    initScore: 0,
    dataTaskType: null,
    isEpic: false,
    taskProject: 0,
    taskName: '',
    taskEstimate: '',
    taskTypeList: [],
    taskLabel: null,
    taskLabelList: [],
    taskEpicLink: null,
    taskEpicLinkList: [],
    taskUser: null,
    taskUserList: [],
    taskPriority: null,
    taskPriorityList: [],
    taskSprint: null,
    taskSprintList: [],
    taskDesc: '',
    taskBuildIos: '',
    taskBuildAndroid: '',
    isLoad: false,
    taskStoryPoint: '',

    submitDisable: false,
    required,
  }),
  watch: {
    dataTaskType: function (val) {
      this.isEpic = val == 2 ? true : false;
    },
  },
  computed: {
    ...mapState('project', ['isPending']),
    availableTaskLabelList() {
      return this.taskLabelList.filter((opt) => this.taskLabel.indexOf(opt) === -1);
    },
    currentProject() {
      let params = this.$route.params;
      if (params.hasOwnProperty('project')) return this.$route.params.project;

      const projects = JSON.parse(localStorage.getItem('projects'));
      return projects[0]['key'];
    },
  },
  methods: {
    ...mapActions('project', ['getBacklogTasks', 'getSprints', 'getBoard', 'getEpics']),
    validateIso(e) {
      this.taskBuildIos = e.target.value.replace(/[^0-9\.]/g, '');
    },
    validateAndroid(e) {
      this.taskBuildAndroid = e.target.value.replace(/[^0-9\.]/g, '');
    },
    formSubmit() {
      this.$refs.createTaskForm.validate().then((success) => {
        if (success) {
          this.submitDisable = true;
          let task = {
            project_key: this.currentProject,
            type_id: this.dataTaskType,
            name: this.taskName,
            priority_id: this.taskPriority.id,
          };
          if (this.taskDesc.length > 0) task.description = this.taskDesc;
          if (this.taskEstimate > 0) task.estimate = this.taskEstimate;
          if (this.taskStoryPoint.length > 0) task.story_point = this.taskStoryPoint;
          if (this.taskBuildAndroid.length > 0) task.check_in_build_android = this.taskBuildAndroid;
          if (this.taskBuildIos.length > 0) task.check_in_build_ios = this.taskBuildIos;

          if (this.taskLabel) task.label_id = this.taskLabel;
          if (this.taskEpicLink) task.epic_number = this.taskEpicLink.number;
          if (this.taskSprint) task.sprint_uuid = this.taskSprint;
          if (this.taskUser) task.assignee_id = this.taskUser.id;
          taskApi
            .create(task)
            .then((res) => {
              this.$bvModal.hide('modal-1');
              // Отображаем сообщение о создании задачи
              this.makeSuccessToast(res.data.number);
              // Очищаем данные формы
              this.clearForm();
              // Обновляем список задач в бэклоге
              if (this.$route.name == 'projects-backlog') {
                this.$store.dispatch('project/updatePending', true);
                axios.all([this.getBacklogTasks(), this.getSprints(), this.getEpics()]).then(() => {
                  this.$store.dispatch('project/updatePending', false);
                });
              }
              // Обновляем список эпиков
              // Обновляем канбан
              if (this.$route.name == 'board') {
                this.getBoard();
              }
              this.submitDisable = false;
            })
            .catch((error) => {
              if (error.response) {
                const errors = getErrors(error.response.data.errors);
                if (errors.general) {
                  this.makeErrorToast(errors.general[0].status, errors.general[0].message);
                }
              }
              this.submitDisable = false;
            });
        }
      });
    },
    showModal() {
      this.isLoad = true;
      const getConfig = projectApi.getConfig(this.currentProject);
      const getDictionaries = projectApi.getDictionaries(this.currentProject);

      Promise.all([getConfig, getDictionaries])
        .then((results) => {
          const config = results[0].data.project_config;
          this.taskUserList = config.members;
          const dictionaries = results[1].data.project_dictionaries;
          this.taskPriorityList = dictionaries.priorities;
          this.taskSprintList = dictionaries.sprints;
          this.taskEpicLinkList = dictionaries.epics;
          this.taskLabelList = dictionaries.labels;
          this.taskTypeList = dictionaries.task_types;
          this.dataTaskType = this.taskType;
        })
        .then(() => {
          this.isLoad = false;
        });
    },
    makeSuccessToast(key) {
      const h = this.$createElement;
      const href = '/' + this.currentProject + '-' + key;
      const vNodesContent = h('div', {}, [
        h('span', {}, 'Задача с ключом '),
        h(
          'a',
          { attrs: { href: href }, class: 'font-weight-bold' },
          this.currentProject + '-' + key,
        ),
        h('span', {}, ' успешно создана'),
      ]);

      this.$bvToast.toast([vNodesContent], {
        title: this.$t('Сreating a task'),
        variant: 'success',
        solid: true,
      });
    },
    makeErrorToast(status, message) {
      this.$bvToast.toast(`Ошибка при создании задачи. ERROR: ${status} ${message}`, {
        title: this.$t('Сreating a task'),
        variant: 'danger',
        solid: true,
      });
    },
    clearForm() {
      this.dataTaskType = null;
      this.taskName = '';
      this.taskPriority = null;
      this.taskDesc = '';
      this.taskEstimate = '';
      this.taskStoryPoint = '';
      this.taskBuildAndroid = '';
      this.taskBuildIos = '';
      this.taskLabel = null;
      this.taskEpicLink = null;
      this.taskSprint = null;
      this.taskUser = null;
    },
  },
};
</script>

<style scoped></style>
