<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <b-dropdown :text="curProject" variant="outline-primary" v-if="projects">
        <b-dropdown-item v-for="project in projects" :key="project.id">
          <router-link
            :to="{ name: 'board', params: { project: project.key }, query: { from: 'project' } }"
            >{{ project.name }} ({{ project.key }})</router-link
          >
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          ><router-link to="/projects">Показать все проекты</router-link></b-dropdown-item
        >
      </b-dropdown>
      <!-- Bookmarks Container -->
      <!--<bookmarks />-->
      <b-button variant="primary" class="ml-1" @click="createTask"> Создать задачу </b-button>

      <b-button
        v-if="$can('create', 'projects')"
        variant="success"
        class="ml-1"
        @click="createProject"
      >
        Создать проект
      </b-button>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <user-dropdown />
    </b-navbar-nav>

    <modal-task-form :taskType="$store.state.studio.modalTaskType"></modal-task-form>
    <modal-project-form ></modal-project-form>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BModal,
  BButton,
  VBModal,
} from 'bootstrap-vue';
import Bookmarks from './components/Bookmarks.vue';
import Locale from './components/Locale.vue';
import SearchBar from './components/SearchBar.vue';
import DarkToggler from './components/DarkToggler.vue';
import CartDropdown from './components/CartDropdown.vue';
import NotificationDropdown from './components/NotificationDropdown.vue';
//import UserDropdown from './components/UserDropdown.vue'
import ModalTaskForm from '@/views/task/ModalTaskForm';
import ModalProjectForm from '@/views/projects/ModalProjectForm';
import UserDropdown from '@/views/user/UserDropdown';

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BButton,
    BModal,
    ModalTaskForm,
    ModalProjectForm,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  directives: {
    'b-modal': VBModal,
  },
  data: () => ({
    projects: JSON.parse(localStorage.getItem('projects')),
  }),
  computed: {
    curProject() {
      return this.$route.params.project ? this.$route.params.project : 'Проекты';
    },
  },
  methods: {
    createTask() {
      this.$store.commit('studio/UPDATE_MODAL_TASK_TYPE', 1);
      this.$bvModal.show('modal-1');
    },
    createProject() {
      // this.$store.commit('studio/UPDATE_MODAL_TASK_TYPE', 1);
      this.$bvModal.show('modal-2');
    },
  },
  mounted() {
    this.projects = JSON.parse(localStorage.getItem('projects'));
    this.user = JSON.parse(localStorage.getItem('userData'));
    // console.log('user:', this.user);
  },
};
</script>
