<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.firstname }} {{ userData.lastname }}
        </p>
        <span class="user-status">Пользователь</span>
      </div>
      <avatar size="sm" :src="userData.avatar"></avatar>
    </template>
    
    <b-dropdown-item
      :to="{ name: 'user'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>
    
    
    <b-dropdown-divider />
    
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Выйти</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>

import {
    BNavItemDropdown, BDropdownItem, BDropdownDivider
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Avatar from './Avatar'
import apiConfig from "@/api/apiConfig";

export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
		    Avatar,
    },
    data() {
        return {
            userData: JSON.parse(localStorage.getItem('userData')),
            avatarText,
        }
    },
    methods: {
        logout() {
            // Remove userData from localStorage
            // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
            localStorage.removeItem(apiConfig.storageTokenKeyName)
            localStorage.removeItem(apiConfig.storageRefreshTokenKeyName)
            
            // Remove userData from localStorage
            localStorage.removeItem('userData')
		
		        // Remove projects from localStorage
		        localStorage.removeItem('projects')
            
            // Reset ability
            //this.$ability.update(initialAbility)
            
            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
        },
    },
}
</script>
