<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { expanded: !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <div
              class="navbar-brand router-link-active cursor-pointer"
              @click.prevent="collapsedMenu"
            >
              <span class="lk-brand-logo">
                <b-img :src="appLogoImage" alt="logo" />
              </span>
              <span class="lk-brand-logo-short">
                <b-img src="@/assets/images/logo/studio-logo-short.svg" alt="logo" />
              </span>
            </div>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- current project -->
    <div class="lk-nav-project navbar-header" v-if="currentProject">
      <div class="lk-nav-project__icon"></div>
      <div class="lk-nav-project__content">
        <div class="lk-nav-project__title">{{ currentProject }}</div>
        <div class="lk-nav-project__desc">Проект по разработке ПО</div>
      </div>
    </div>
    <!-- / current project -->

    <ul class="navigation navigation-main">
      <li
        :class="{
          active: $router.currentRoute.name == 'projects',
          'nav-item': true,
        }"
      >
        <router-link to="/projects" class="d-flex align-items-center">
          <feather-icon icon="FolderIcon" size="14" />
          <span class="menu-title text-truncate">Projects</span>
        </router-link>
      </li>
      <template v-if="currentProject">
        <li
          :class="{
            active: $router.currentRoute.name == 'projects-backlog',
            'nav-item': true,
          }"
        >
          <router-link :to="`/project/${currentProject}/backlog`" class="d-flex align-items-center">
            <feather-icon icon="ServerIcon" size="14" />
            <span class="menu-title text-truncate">Backlog</span>
          </router-link>
        </li>
        <li
          :class="{
            active: $router.currentRoute.name == 'board',
            'nav-item': true,
          }"
        >
          <router-link :to="`/project/${currentProject}/board`" class="d-flex align-items-center">
            <feather-icon icon="ColumnsIcon" size="14" />
            <span class="menu-title text-truncate">Sprint</span>
          </router-link>
        </li>
        <li
          v-if="$can('read', 'doc_schema_release')"
          :class="{
            active: $router.currentRoute.name.includes('release'),
            'nav-item': true,
          }"
        >
          <router-link
            :to="`/project/${currentProject}/releases`"
            class="d-flex align-items-center"
          >
            <feather-icon icon="ColumnsIcon" size="14" />
            <span class="menu-title text-truncate">Release</span>
          </router-link>
        </li>
      </template>
    </ul>

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content-slk scroll-area"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <vertical-nav-menu-items :items="navMenuItems" class="navigation navigation-main" />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
    <small id="slk-version" class="lk-nav-project__desc text-muted">Версия LK 2.0</small>
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { BLink, BImg } from 'bootstrap-vue';
import { provide, computed, ref } from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';
import { $themeConfig } from '@themeConfig';
import VerticalNavMenuItems from '@core/layouts/layout-vertical/components/vertical-nav-menu/components/vertical-nav-menu-items/VerticalNavMenuItems.vue';
import useVerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu';

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  methods: {
    collapsedMenu() {
      this.toggleCollapsed();
    },
  },
  computed: {
    currentProject() {
      let params = this.$route.params;
      if (params.hasOwnProperty('project')) return this.$route.params.project;
      return null;
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide('isMouseHovered', isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon',
    );

    // App Name
    const { appName, appLogoImage } = $themeConfig.app;

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    };
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
</style>
