var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-2","no-close-on-backdrop":"","content-class":"shadow","title":"Создать проект","size":"lg"},on:{"show":_vm.showModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":function($event){return _vm.$bvModal.hide('modal-2')}}},[_vm._v(" Отмена ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","disabled":_vm.submitDisable},on:{"click":_vm.formSubmit}},[_vm._v(" Создать ")])]},proxy:true}])},[(_vm.isLoad)?_c('div',{staticClass:"slk-loader"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1):_vm._e(),_c('validation-observer',{ref:"createTaskForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-form-group',{attrs:{"label":"Название проекта"}},[_c('validation-provider',{attrs:{"name":"projectName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Название проекта"},model:{value:(_vm.projectName),callback:function ($$v) {_vm.projectName=$$v},expression:"projectName"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("Поле обязательно для заполнения")]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Ключ проекта"}},[_c('validation-provider',{attrs:{"name":"keyProject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Ключ проекта"},model:{value:(_vm.keyProject),callback:function ($$v) {_vm.keyProject=$$v},expression:"keyProject"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("Поле обязательно для заполнения")]):_vm._e()]}}],null,true)})],1),_c('b-row',[_c('b-col',{attrs:{"sm":"10"}},[_c('b-form-group',{attrs:{"label":"Участники"}},[_c('v-select',{attrs:{"options":_vm.allUserList,"filter":_vm.fuseSearch,"placeholder":"Выберите участников","label":"firstname","multiple":""},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var id = ref.id;
var firstname = ref.firstname;
var lastname = ref.lastname;
var avatar = ref.avatar;
return [_c('div',{staticClass:"vSelect-studio-img"},[_c('div',{staticClass:"vSelect-avatar"},[_c('img',{attrs:{"src":avatar}})]),_c('span',[_vm._v(" "+_vm._s(firstname)+" "+_vm._s(lastname))])])]}},{key:"option",fn:function(ref){
var id = ref.id;
var firstname = ref.firstname;
var lastname = ref.lastname;
var avatar = ref.avatar;
return [_c('div',{staticClass:"vSelect-studio-img"},[_c('div',{staticClass:"vSelect-avatar"},[_c('img',{attrs:{"src":avatar}})]),_c('span',[_vm._v(" "+_vm._s(firstname)+" "+_vm._s(lastname))])])]}}],null,true),model:{value:(_vm.projectUserList),callback:function ($$v) {_vm.projectUserList=$$v},expression:"projectUserList"}})],1),_c('b-form-group',{attrs:{"label":"Workflow"}},[_c('v-select',{attrs:{"options":_vm.workflowAllSteps,"placeholder":"Выберите Workflows","label":"name","multiple":""},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_c('span',[_vm._v(_vm._s(name))])]}}],null,true),model:{value:(_vm.workflowList),callback:function ($$v) {_vm.workflowList=$$v},expression:"workflowList"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }