var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-accordion menu-shadow",class:[
    { expanded: !_vm.isVerticalMenuCollapsed || (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered) },
    _vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light' ]},[_c('div',{staticClass:"navbar-header expanded"},[_vm._t("header",function(){return [_c('ul',{staticClass:"nav navbar-nav flex-row"},[_c('li',{staticClass:"nav-item mr-auto"},[_c('div',{staticClass:"navbar-brand router-link-active cursor-pointer",on:{"click":function($event){$event.preventDefault();return _vm.collapsedMenu.apply(null, arguments)}}},[_c('span',{staticClass:"lk-brand-logo"},[_c('b-img',{attrs:{"src":_vm.appLogoImage,"alt":"logo"}})],1),_c('span',{staticClass:"lk-brand-logo-short"},[_c('b-img',{attrs:{"src":require("@/assets/images/logo/studio-logo-short.svg"),"alt":"logo"}})],1)])])])]},{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive,"toggleCollapsed":_vm.toggleCollapsed,"collapseTogglerIcon":_vm.collapseTogglerIcon})],2),(_vm.currentProject)?_c('div',{staticClass:"lk-nav-project navbar-header"},[_c('div',{staticClass:"lk-nav-project__icon"}),_c('div',{staticClass:"lk-nav-project__content"},[_c('div',{staticClass:"lk-nav-project__title"},[_vm._v(_vm._s(_vm.currentProject))]),_c('div',{staticClass:"lk-nav-project__desc"},[_vm._v("Проект по разработке ПО")])])]):_vm._e(),_c('ul',{staticClass:"navigation navigation-main"},[_c('li',{class:{
        active: _vm.$router.currentRoute.name == 'projects',
        'nav-item': true,
      }},[_c('router-link',{staticClass:"d-flex align-items-center",attrs:{"to":"/projects"}},[_c('feather-icon',{attrs:{"icon":"FolderIcon","size":"14"}}),_c('span',{staticClass:"menu-title text-truncate"},[_vm._v("Projects")])],1)],1),(_vm.currentProject)?[_c('li',{class:{
          active: _vm.$router.currentRoute.name == 'projects-backlog',
          'nav-item': true,
        }},[_c('router-link',{staticClass:"d-flex align-items-center",attrs:{"to":("/project/" + _vm.currentProject + "/backlog")}},[_c('feather-icon',{attrs:{"icon":"ServerIcon","size":"14"}}),_c('span',{staticClass:"menu-title text-truncate"},[_vm._v("Backlog")])],1)],1),_c('li',{class:{
          active: _vm.$router.currentRoute.name == 'board',
          'nav-item': true,
        }},[_c('router-link',{staticClass:"d-flex align-items-center",attrs:{"to":("/project/" + _vm.currentProject + "/board")}},[_c('feather-icon',{attrs:{"icon":"ColumnsIcon","size":"14"}}),_c('span',{staticClass:"menu-title text-truncate"},[_vm._v("Sprint")])],1)],1),(_vm.$can('read', 'doc_schema_release'))?_c('li',{class:{
          active: _vm.$router.currentRoute.name.includes('release'),
          'nav-item': true,
        }},[_c('router-link',{staticClass:"d-flex align-items-center",attrs:{"to":("/project/" + _vm.currentProject + "/releases")}},[_c('feather-icon',{attrs:{"icon":"ColumnsIcon","size":"14"}}),_c('span',{staticClass:"menu-title text-truncate"},[_vm._v("Release")])],1)],1):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"shadow-bottom",class:{ 'd-block': _vm.shallShadowBottom }}),_c('vue-perfect-scrollbar',{staticClass:"main-menu-content-slk scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":function (evt) {
        _vm.shallShadowBottom = evt.srcElement.scrollTop > 0;
      }}},[_c('vertical-nav-menu-items',{staticClass:"navigation navigation-main",attrs:{"items":_vm.navMenuItems}})],1),_c('small',{staticClass:"lk-nav-project__desc text-muted",attrs:{"id":"slk-version"}},[_vm._v("Версия LK 2.0")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }